/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { IS_DEV } from "../environment"
import {
  Flex,
  Vis,
  MOBILE_ONLY_CSS_WRAPPER
} from "./common"
import SEO from '../components/seo'

export const MobileLayout = styled(Flex)`
  flex-direction: column !important;
  // display on mobile only
  @media (min-width: 800px) {
    display: none;
  }
`

// disable for production!
const debug = IS_DEV

const FlexOutter = styled(Flex)`
flex: 1 1 auto;
${props => MOBILE_ONLY_CSS_WRAPPER(`${props.fitToHeightOnMobile ? `
top:0;
right:0;
left:0;
bottom:0;
position:absolute;
`: ''}`)}
`

export const ColumnLayout = styled(({ children = null, fitToHeightOnMobile = false, title = null, ...props }) => {
  {/* this should be the outer wrapper -> full height on mobile */ }
  return (
    <FlexOutter fitToHeightOnMobile={fitToHeightOnMobile} flex="col hcenter" className="child">
      <SEO title={title}/>
      {/* this is just for debug vvv */}
      {debug && (<>
        <Vis.MobileOnly flex="nogrow"><Flex flex="center" style={{ border: '1px solid black', padding: '5px', background: 'lime' }}>MobileOnly</Flex></Vis.MobileOnly>
        <Vis.TabletOnly flex="nogrow"><Flex flex="center" style={{ border: '1px solid black', padding: '5px', background: 'magenta' }}>TabletOnly</Flex></Vis.TabletOnly>
        <Vis.DesktopOnly flex="nogrow"><Flex flex="center " style={{ border: '1px solid black', padding: '5px', background: 'yellow' }}>DesktopOnly</Flex></Vis.DesktopOnly>
      </>)}
      {/* this is the children */}
      <Flex flex="col w100" style={{ maxWidth: '1440px' }}>
        {children}
      </Flex>
    </FlexOutter>
  )
})`

min-height:100vh;

`

export default { MobileLayout, ColumnLayout }
